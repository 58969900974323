<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Функционал: Жалобы</strong>
    </div>
    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Жалобы</h5>
        </div>
      </div>
      <div class="card-body">
        <complaints-table :list="list" :paginate="pagination" @handleTableChange="handleTableChange" @getList="getList" />
      </div>
    </div>
  </div>
</template>

<script>
import ComplaintsTable from '@/components/functional/complaints/ComplaintsTable'

export default {
  name: 'index',
  data() {
    return {
      list: [],
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList(page = this.pagination.current) {
      const params = {
        page,
        search: this.search ? this.search : null,
      }

      const complaints = (await this.$services.get('admin/complaints', { params })).data.data.complaints

      this.list = complaints.data
      this.pagination = {
        current: complaints.current_page,
        total: complaints.total,
        pageSize: complaints.per_page,
      }
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current
    },
  },
  components: { ComplaintsTable },
}
</script>
