<template>
  <a-modal v-model="visible" :title="`Просмотр: ${form.name}`" width="100%" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Ok
      </a-button>
    </template>
    <div class="info_container">
      <div class="main_img">
        <div>
          <img :src="form.mainImage" alt="main image"/>
        </div>
      </div>
      <div class="info_container-item name">
        <h3>Название</h3>
        <span>{{ form.name }}</span>
      </div>
      <div class="info_container-item name">
        <h3>Описание</h3>
        <span>{{ form.description }}</span>
      </div>
      <div class="info_container-item">
        <h3>Уникальный ID</h3>
        <span>{{ form.unique_id }}</span>
      </div>
      <div class="info_container-item">
        <h3>Номер</h3>
        <span>{{ form.number_car || '-' }}</span>
      </div>
      <div class="info_container-item">
        <h3>Vin код</h3>
        <span>{{ form.vin_code }}</span>
      </div>
      <div class="info_container-item">
        <h3>Дата создания</h3>
        <span>{{ form.created_at }}</span>
      </div>
      <div class="info_container-item">
        <h3>Регион</h3>
        <span>{{ form.region }}</span>
      </div>
      <div class="info_container-item">
        <h3>Город</h3>
        <span>{{ form.city }}</span>
      </div>
      <div class="info_container-item">
        <h3>Тип кузова</h3>
        <span>{{ form.car_serie }}</span>
      </div>
      <div class="info_container-item">
        <h3>Пробег</h3>
        <span>{{ form.mileage }} тыс. км</span>
      </div>
      <div class="info_container-item">
        <h3>Модификация</h3>
        <span>{{ form.modification | getFormattedValue }}</span>
      </div>
      <div class="info_container-item">
        <h3>Привод</h3>
        <span>{{ form.drive_unit | getFormattedValue }}</span>
      </div>
      <div class="info_container-item">
        <h3>Коробка</h3>
        <span>{{ form.transmission | getFormattedValue }}</span>
      </div>
      <div class="info_container-item">
        <h3>Цвет</h3>
        <div v-if="form.color" class="d-flex align-items-center">
          <span>{{ form.color.name | getFormattedValue }}</span>
          <span class="color_image"><img :src="form.color.icon" alt="color" /></span>
        </div>
      </div>
      <div class="info_container-item">
        <h3>Топливо</h3>
        <span>{{ form.fuel | getFormattedValue }}</span>
      </div>
      <div class="info_container-item">
        <h3>Расход город</h3>
        <span>{{ form.consumption_city }} л</span>
      </div>
      <div class="info_container-item">
        <h3>Расход трасса</h3>
        <span>{{ form.consumption_trail }} л</span>
      </div>
      <div class="info_container-item">
        <h3>Расход смешанный</h3>
        <span>{{ form.consumption_mixed }} л</span>
      </div>
      <div class="info_container-item">
        <h3>Объем ДВС</h3>
        <span>{{ form.engine_capacity_id / 1000 }} л</span>
      </div>
      <div class="info_container-item">
        <h3>Мощность ДВС</h3>
        <span>{{ form.engine_power_id | getFormattedValue }} л.c.</span>
      </div>
      <div class="info_container-item">
        <h3>Количество дверей</h3>
        <span>{{ form.door | getFormattedValue }} </span>
      </div>
      <div class="info_container-item">
        <h3>Состояние</h3>
        <ol>
          <li v-for="item in form.car_condition" :key="item">{{ item }}</li>
        </ol>
      </div>
      <div class="info_container-item">
        <h3>Безопасность</h3>
        <ol>
          <li v-for="item in form.car_security" :key="item">{{ item }}</li>
        </ol>
      </div>
      <div class="info_container-item">
        <h3>Мультимедиа</h3>
        <ol>
          <li v-for="item in form.car_multimedia" :key="item">{{ item }}</li>
        </ol>
      </div>
      <div class="info_container-item">
        <h3>Доп опции</h3>
        <ol>
          <li v-for="item in form.car_comfort" :key="item">{{ item }}</li>
        </ol>
      </div>
      <div class="info_container-item">
        <h3>Другое</h3>
        <ol>
          <li v-for="item in form.car_another" :key="item">{{ item }}</li>
        </ol>
      </div>
      <div class="info_container-item">
        <h3>Расстаможено</h3>
        <span>{{ form.is_cleared ? 'Да' : 'Нет' }} </span>
      </div>
      <div class="info_container-item">
        <h3>В Украине</h3>
        <span>{{ form.is_ukraine ? 'Да' : 'Нет' }} </span>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'PreviewModal',
  data() {
    return {
      visible: false,
      form: {},
    }
  },
  methods: {
    async open(id) {
      this.visible = true
      try {
        this.form = (await this.$services.get(`admin/products/${id}`)).data.data.product
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.visible = false
      this.product = {}
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .ant-modal {
  padding: 24px 16px;
  .ant-modal-title {
    font-weight: bolder;
    font-size: 24px;
  }
}
.info_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &-item {
    width: calc(33% - 21px);
    margin-bottom: 24px;
    h3 {
      font-size: 20px;
    }
    span {
      font-size: 16px;
    }
    .color_image {
      width: 24px;
      height: 24px;
      margin-left: 12px;
      display: block;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .name {
    text-align: center;
    width: 100%;
  }
  .main_img {
    width: 100%;
    padding: 24px 0;
    div {
      width: 500px;
      height: auto;
      margin: 0 auto;
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

</style>
