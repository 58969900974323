<template>
  <a-modal v-model="visible" title="Редактирование" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click.prevent.stop="submit" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>
    <a-form-model :model="form">
      <a-form-model-item label="Комментарий" required>
        <a-input  v-model.number="form.comment"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: 'ToEditProductModal',
  data() {
    return {
      form: {
        comment: '',
      },
      id: '',
      visible: false,
    }
  },
  computed: {
    isDisabled() {
      return !this.form.comment
    },
  },
  methods: {
    open(id) {
      this.id = id
      this.visible = true
    },
    close() {
      this.visible = false
      this.id = ''
      this.form = {
        comment: '',
      }
    },
    async submit() {
      try {
        await this.$services.put(`admin/complaints/edit/${this.id}`, this.form)
        this.$emit('getList')
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>

</style>
