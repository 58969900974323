import { render, staticRenderFns } from "./ProductPreviewModal.vue?vue&type=template&id=04878abb&scoped=true&"
import script from "./ProductPreviewModal.vue?vue&type=script&lang=js&"
export * from "./ProductPreviewModal.vue?vue&type=script&lang=js&"
import style0 from "./ProductPreviewModal.vue?vue&type=style&index=0&id=04878abb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04878abb",
  null
  
)

export default component.exports